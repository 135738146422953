<template>
  <b-modal
    :visible="show"
    :title="title"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    size="lg"
    :hide-header-close="false"
    :hide-footer="true"
    :centered="true"
    @close="onCloseClick"
  >
    <div v-if="profile">
      <b-col>
        <div class="text-center">
          <b-avatar
            v-if="!profile.imgDataUrl"
            variant="primary"
            :text="userInitials(profile.user ? profile.user : profile)"
            :src="userProfileImage(profile.isMyProfile, profile.imgDataUrl)"
            size="10rem"
          />
          <img
            v-else
            :src="profile.imgDataUrl"
            alt="profile_image"
            style="width: 10rem"
          >
        </div>
      </b-col>
      <b-col class="text-center mt-1">
        <h2 class="font-weight-bolder">
          {{ profile.full_name ? profile.full_name : profile.name }}
        </h2>

        <template v-if="profile.isMyProfile">
          <b-badge
            v-for="(role, index) in participantRoles(profile.roles)"
            :key="index"
            variant="primary"
            class="font-weight-normal"
            style="margin-right: 2px"
          >
            {{
              role === userRoles.MENTOR
                ? profile.mentorDisplay
                : profile.menteeDisplay
            }}
          </b-badge>
        </template>

        <b-badge
          v-else
          variant="primary"
          class="font-weight-normal"
          style="margin-right: 2px"
        >
          {{ profile.role === userRoles.MENTOR ? profile.mentorDisplay : profile.menteeDisplay }}
        </b-badge>
      </b-col>

      <hr>
      <p class="text-center font-weight-bolder mt-3">
        Select how you are going to contact
      </p>

      <b-row class="flex-column align-items-center pb-1">
        <b-col sm="4">
          <b-button
            class="w-100 mt-1"
            variant="primary"
            @click="onSelectContact(contactOptions.videoCall)"
          >
            Video Call
          </b-button>
        </b-col>
        <b-col sm="4">
          <b-button
            class="w-100 mt-1"
            variant="primary"
            @click="onSelectContact(contactOptions.call)"
          >
            Call
          </b-button>
        </b-col>
        <b-col sm="4">
          <b-button
            class="w-100 mt-1"
            variant="primary"
            @click="onSelectContact(contactOptions.message)"
          >
            Message
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { BRow, BCol, BBadge, BAvatar, BButton } from "bootstrap-vue";
import { userRoles } from "@/models/userRoles";
const contactOptions = {
  videoCall: 1,
  call: 2,
  message: 3,
};
export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
    },
    profile: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      title: "Contact With MS Teams",
      message: "",
      okTitle: "OK",
      cancelTitle: "Cancel",
      resolveReject: null,
      userRoles,
      contactOptions,
    };
  },
  methods: {
    onCloseClick() {
      this.$emit('closeTeamsModal');
    },
    userInitials(userName) {
      const firstInt = Array.from(userName?.first_name)[0];
      const lastInt = Array.from(userName?.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    participantRoles(roles) {
      if (roles && roles.length) {
        return roles.filter(
          r => r === userRoles.MENTOR || r === userRoles.MENTEE
        );
      }
    },
    userProfileImage(isMyProfile, profileImage) {
      if (isMyProfile) {
        return this.profile?.profile_image?.url || "";
      }
      return profileImage || "";
    },
    onSelectContact(contactType) {
      let url;
      switch(contactType) {
      case contactOptions.videoCall:
        url = `https://teams.microsoft.com/l/call/0/0?users=${this.profile.teamsContact}&withVideo=true`;
        break;
      case contactOptions.call:
        url = `https://teams.microsoft.com/l/call/0/0?users=${this.profile.teamsContact}`;
        break;
      case contactOptions.message:
        url = `https://teams.microsoft.com/l/chat/0/0?users=${this.profile.teamsContact}`;
        break;
      }
      window.open(url, '_blank');
    },
  },
};
</script>
